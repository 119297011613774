import { E_PatientDetailsSection } from "@backend/graph/patient-details/patient-details-base";
import { ILocalisationConfig } from "./localisation.interface";

const nz: ILocalisationConfig = {
  requiredContactFields: {
    [E_PatientDetailsSection.Address]: ["address_line_1", "address_line_3", "county", "postcode"],
  },
};

export default nz;
