/* eslint-disable import/no-cycle */
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, filter, firstValueFrom, map } from "rxjs";
import { SubSink } from "subsink";
import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { CachedHttpService } from "./cached-http.service";
import {
  BookableAppointmentBase,
  BookableAppointmentCategoryBase,
  BookableAppointmentSiteBase,
} from "../../../../../../backend/src/graph/bookable_appointments/bookable-appointment-base";
import { NavigationService } from "./navigation.service";
import {
  BookableAppointmentEntry,
  BookableAppointmentSlotEntry,
  BookableAppointmentSiteEntry,
  BookableAppointmentCategoryEntry,
  BookableAppointmentPractitionerEntry,
} from "src/app/data_model/bookable-appointment";
import { JWTService } from "./jwt.service";
import { BrandService } from "./brand.service";
import { PatientsService } from "./patients.service";
import { FeatureFlagsService } from "./feature-flags.service";
import { NavigationExtras, Router } from "@angular/router";
import cloneDeep from "lodash/cloneDeep";

export const NO_THANKS_ID = "no";
export const SOMETHING_ELSE_ID = "something_else";
const SELECTED_SITE = "selectedSite";
const SELECTED_SITE_ID = "selectedSiteId";
const SELECTED_APPOINTMENT_KEY = "selectedAppointment";
const SELECTED_ADDITIONAL_APPOINTMENTS_KEY = "selectedAdditionalAppointments";
const SELECTED_SLOT_KEY = "selectedSlot";
const SELECTED_CATEGORY_KEY = "selectedCategory";
const IS_NEW_PATIENT_KEY = "isNewPatient";
const AVAILABILITY_QUERY_PARAMS_KEY = "availabilityQueryParams";

import { PractitionersService } from "./practitioners.service";
import { CommonService } from "./common.service";
import Bugsnag from "@bugsnag/js";
import { AppointmentAnalyticsService } from "./analytics/appointment-analytics.service";
import { ANY_PRACTITIONER_ID } from "@shared/constants";
import { E_SlotVariantFeature } from "@backend/common/enums/feature-flags.enum";
import { LocationService } from "./location.service";
import { SortPractitioners } from "../utils/sort-practitioners";
import { I_SelectedAppointmentSlot } from "src/app/bookable-appointments/availability/slot-basketless/slot.component";
import { AppointmentCardEntry } from "../components/my-appointments/appointment-card/appointment-card.component";
import { PaymentsService } from "./payments.service";
import { AppointmentsService, HELD_APPOINTMENTS_KEY } from "./appointments.service";
import { OverlayService } from "./overlay.service";
import { BrandInfoSiteBase } from "@backend/graph/brand_info/brand-info-base";
import { CacheService } from "./cache.service";
import dayjs, { Dayjs } from "dayjs";
import { E_AppointmentEventActions } from "src/app/data_model/appointment";
import { PatientStatus, E_PatientStatus } from "@shared/patient-status";
import { PatientActionsCacheService } from "./patient-actions-cache.service";
import { E_Patient_Actions_Type } from "@backend/graph/patient_actions/patient-action-base";
import { AnalyticsService, METRIC } from "./analytics.service";
import { isDefined } from "@shared/utils";
import { I_Referrer } from "@backend/common/interfaces/referrer";
import { ShortCodesService } from "./short-codes.service";

export type CompletedAction = {
  site_id: string;
  type: E_Patient_Actions_Type;
  referrer: I_Referrer;
};

export enum E_StripeCheckoutSessionStatus {
  created = "created",
  processing = "processing",
  processed = "processed",
}

const STRIPE_CHECKOUT_SESSION_STORAGE_KEY = "stripe-checkout-session-status";

@Injectable({
  providedIn: "root",
})
export class BookableApptsService {
  private _selectedSiteIdStore: string | null = null;
  private _subs = new SubSink();
  private _selectedAppointment: BookableAppointmentEntry | null = null;
  private _selectedAdditionalAppointments: Array<BookableAppointmentEntry> | null = new Array<BookableAppointmentEntry>();
  private _selectedSlot: BookableAppointmentSlotEntry | null = null;
  private _selectedSite: BookableAppointmentSiteEntry | null = null;
  private _bookableAppts: Array<BookableAppointmentSiteEntry> | Array<BookableAppointmentCategoryEntry>;
  private _selectedCategory: BookableAppointmentCategoryEntry | null = null;
  private _slotVariant: E_SlotVariantFeature;
  private _graphError: Error | null = null;
  private _bookedAppointmentCards: Array<AppointmentCardEntry> = [];
  private _availabilityQueryParams: Record<string, any> | null = null;
  private _stripeCheckoutSessionStatus: E_StripeCheckoutSessionStatus;
  public requiredToSelectSite: boolean;
  public onSelectedApptChanged: BehaviorSubject<BookableAppointmentEntry | null> = new BehaviorSubject(null);
  public onSelectedAdditionalApptsChanged: BehaviorSubject<Array<BookableAppointmentEntry> | null> = new BehaviorSubject([]);
  public onSelectedSlotChanged: BehaviorSubject<BookableAppointmentSlotEntry | null> = new BehaviorSubject(null);
  public onBookableApptsChanged: BehaviorSubject<Array<BookableAppointmentSiteEntry | BookableAppointmentCategoryEntry> | null> = new BehaviorSubject(null);
  public onBookableApptsError: Subject<boolean> = new Subject();
  public onAppointmentBookingError: Subject<boolean> = new Subject();
  public original_bookable_data: Array<BookableAppointmentSiteBase>;

  constructor(
    private _cachedHttpService: CachedHttpService,
    private _httpService: HttpService,
    private _navigationService: NavigationService,
    private _jwtService: JWTService,
    private _brandService: BrandService,
    private _patientsService: PatientsService,
    private _featureFlagsService: FeatureFlagsService,
    private _practitionersService: PractitionersService,
    private _commonService: CommonService,
    private _appointmentAnalyticsService: AppointmentAnalyticsService,
    private _patientService: PatientsService,
    private _router: Router,
    private _locationService: LocationService,
    private _paymentsService: PaymentsService,
    private _appointmentsService: AppointmentsService,
    private _overlayService: OverlayService,
    private _cacheService: CacheService,
    private _patientActionsCacheService: PatientActionsCacheService,
    private _analyticsService: AnalyticsService,
    private _shortCodesService: ShortCodesService
  ) {
    // eslint-disable-next-line complexity
    this._subs.sink = this.onBookableApptsChanged.subscribe(() => {
      if (!this.selectedAppointment && this.availabilityQueryParams) {
        // This is the minimum we need in order to get availability
        if (!this.availabilityQueryParams.appointment_type_id || !this.original_bookable_data.length) {
          this._navigationService.navigateToBooking();

          return;
        }

        const site_id = this.availabilityQueryParams?.site_id || "";
        let selected_appointment = {} as BookableAppointmentEntry;
        let selected_category = {} as BookableAppointmentCategoryEntry;
        const additional_appointment_type_ids = this.availabilityQueryParams?.additional_appointments
          ? JSON.parse(this.availabilityQueryParams?.additional_appointments).map((item) => item.id)
          : [];

        this._selectedAdditionalAppointments = new Array<BookableAppointmentEntry>();

        for (const site of this.original_bookable_data) {
          for (const category of site.appointment_categories) {
            for (const appointment of category.site_appointment_types) {
              if (appointment.appointment_type_id === this.availabilityQueryParams.appointment_type_id && site.id === site_id) {
                const bookable_appt = new BookableAppointmentEntry(appointment);
                selected_appointment = this.getNewSiteAppointmentType(site_id, bookable_appt);
                selected_category = new BookableAppointmentCategoryEntry(category);
              } else if (additional_appointment_type_ids.includes(appointment.appointment_type_id) && site.id === site_id) {
                const bookable_appt = new BookableAppointmentEntry(appointment);

                this._selectedAdditionalAppointments.push(this.getNewSiteAppointmentType(site_id, bookable_appt));
              }
            }
          }
        }
        this.selectedCategory = selected_category;
        this.selectedAppointment = selected_appointment;
      }
    });
    this._subs.sink = this._appointmentsService.onBookingError.pipe(filter((error) => error)).subscribe(() => {
      this._overlayService.close(); // Must close the overlay if there is an error so the patient can try again
      this.navigate("error");
    });

    this.slotVariant = this._featureFlagsService.slotVariants;
  }

  public get isNewPatient(): boolean {
    return this._cacheService.getSession(IS_NEW_PATIENT_KEY) === "true";
  }

  public set isNewPatient(value: boolean) {
    this._cacheService.setSession(IS_NEW_PATIENT_KEY, value.toString());
  }

  private get _selectedSiteId(): string | null {
    return this._selectedSiteIdStore;
  }

  private set _selectedSiteId(value: string) {
    this._selectedSiteIdStore = value;
    this._cacheService.setSession(SELECTED_SITE_ID, value);
  }

  public get canBook(): boolean {
    if (this._patientService?.patientInfo?.prevent_appointment_booking) return false;
    return this.bookableAppts?.length > 0;
  }

  public get shouldSkipNewExisting(): boolean {
    if (!this._jwtService.isPUBLIC()) return false;

    let has_new_appointments = false;
    if (this.original_bookable_data) {
      for (const site of this.original_bookable_data) {
        if (site.appointment_categories.find((ac) => ac.for_new_patients)) {
          has_new_appointments = true;
          break;
        }
      }
    }

    return (
      !has_new_appointments ||
      (this._brandService.brand.existing_patients_only_on_brand_url &&
        (this._brandService.restrictedSiteId === "" || this._brandService.restrictedSiteId == null))
    );
  }

  public get graphError(): Error | null {
    return this._graphError;
  }

  public get showPractitionerSelectionScreen(): boolean {
    if (this.emergencyAppointmentSelected) return false;

    /*
      Appointments that are both dental & hygiene appointments (Consultations) require a practitioner selection
      New users also require a practitioner selection
    */
    return (
      (!this._jwtService.isPatient() || !!(this.selectedCategory?.dental_appointment && this.selectedCategory.hygiene_appointment)) &&
      !!(this.selectedAppointment?.practitioners && this.selectedAppointment?.practitioners?.length > 1)
    );
  }

  public get selectedPractitionerNamesString(): string {
    const practitionerNames = this.selectedPractitioners.map((p) => p.full_name);
    if (practitionerNames.length < 2) return practitionerNames[0];
    const last = practitionerNames.pop();
    return `${practitionerNames.join(", ")} and ${last}`;
  }

  public get bookedAppointmentCards(): Array<AppointmentCardEntry> {
    return this._bookedAppointmentCards;
  }

  private _generateAppointmentHtml(name: string, practitionerName?: string): string {
    const apptName = `<span class="font-semibold">${name}</span>`;
    return !!practitionerName ? `${apptName} with <span class="font-semibold">${practitionerName}</span>` : apptName;
  }

  public get selectedAppointmentNamesHtml(): string {
    const any_practitioner_label = "Any practitioner";
    const appointments = new Array<BookableAppointmentEntry>();

    if (this.selectedAppointment) appointments.push(this.selectedAppointment);
    if (this.selectedAdditionalAppointments) appointments.push(...this.selectedAdditionalAppointments);

    const appointment_groups_by_full_name: Record<string, Array<BookableAppointmentEntry>> = appointments.reduce((acc, appointment) => {
      if (!appointment) return acc;
      const key = appointment.selected_practitioner ? appointment.selected_practitioner.full_name : any_practitioner_label;
      if (!acc[key]) acc[key] = [];
      acc[key].push(appointment);
      return acc;
    }, {});

    const appointmentStrings = new Array<string>();
    Object.entries(appointment_groups_by_full_name).forEach(([key, value]) => {
      const practitionerName = (key.trim() === any_practitioner_label ? key.toLowerCase() : key).trim();

      const appointmentNames = value.map((v) => v.name);
      if (appointmentNames.length < 2) {
        appointmentStrings.push(this._generateAppointmentHtml(appointmentNames[0], practitionerName));
        return;
      }
      const last = `${appointmentNames.pop()}`;
      appointmentStrings.push(
        `${appointmentNames.map((an) => this._generateAppointmentHtml(an)).join(", ")} and ${this._generateAppointmentHtml(last, practitionerName)}`
      );
    });
    return appointmentStrings.join(" and ");
  }

  public get areSelectedPractitionersPreferred(): boolean {
    return this.selectedPractitioners.every((p) => p.is_preferred);
  }

  public get selectedPractitioners(): Array<BookableAppointmentPractitionerEntry> {
    const practitioners = new Array<BookableAppointmentPractitionerEntry>();

    if (this.selectedAppointment) practitioners.push(this.selectedAppointment.selected_practitioner);
    if (this.selectedAdditionalAppointments) practitioners.push(...this.selectedAdditionalAppointments.map((appt) => appt?.selected_practitioner));

    // ensure there are no duplicates (e.g. dentist and hygienist are the same person)
    return [...new Map(practitioners.map((item) => [item.id, item])).values()];
  }

  public get availabilityQueryParams(): Record<string, any> | null {
    return this._availabilityQueryParams;
  }

  public set availabilityQueryParams(value: Record<string, any> | null) {
    this._availabilityQueryParams = value;

    if (value) {
      this._cacheService.setSession(AVAILABILITY_QUERY_PARAMS_KEY, JSON.stringify(value));
    } else {
      this._cacheService.deleteSession(AVAILABILITY_QUERY_PARAMS_KEY);
    }
  }

  public get stripeCheckoutSessionStatus(): E_StripeCheckoutSessionStatus {
    if (!this._stripeCheckoutSessionStatus)
      this._stripeCheckoutSessionStatus = this._cacheService.getSession(STRIPE_CHECKOUT_SESSION_STORAGE_KEY) as E_StripeCheckoutSessionStatus;

    return this._stripeCheckoutSessionStatus;
  }

  public set stripeCheckoutSessionStatus(status: E_StripeCheckoutSessionStatus) {
    this._stripeCheckoutSessionStatus = status;

    this._cacheService.setSession(STRIPE_CHECKOUT_SESSION_STORAGE_KEY, status);
  }

  private _createNewSiteAppointmentType(appointment: BookableAppointmentBase): BookableAppointmentEntry {
    const appointmentObj = new BookableAppointmentEntry(appointment);
    appointmentObj.start_time = this.availabilityQueryParams?.start_time ? dayjs(this.availabilityQueryParams.start_time) : dayjs();

    const practitioner_id = this._getPractitionerIdFromQueryParams(appointmentObj.appointment_type_id);

    if (practitioner_id) {
      // If the user switches location then default to the first available practitioner
      appointmentObj.selected_practitioner = appointmentObj.practitioners.find((item) => item.id === practitioner_id) || appointmentObj.practitioners[0];
    }

    return appointmentObj;
  }

  private _getPractitionerIdFromQueryParams(appointment_type_id: string): string | null {
    if (!this.availabilityQueryParams) this.availabilityQueryParams = {};

    if (appointment_type_id === this.availabilityQueryParams.appointment_type_id) return this.availabilityQueryParams.practitioner_id;

    if (!this.availabilityQueryParams.additional_appointments) return null;

    return JSON.parse(this.availabilityQueryParams.additional_appointments).reduce(
      (acc, item) => (item.id === appointment_type_id ? item.practitioner_id : acc),
      null
    );
  }

  public get emergencyAppointmentSelected(): boolean {
    if (!this.selectedCategory) return false;

    const { dental_appointment, hygiene_appointment, supports_routine_appointments } = this.selectedCategory;
    return dental_appointment && !hygiene_appointment && !supports_routine_appointments;
  }

  private _getSelectedPractitionerId(appointment: BookableAppointmentEntry | null): string | null {
    if (!appointment) return null;

    // If we are searching for Any Practitioner, then do not check for a preferred practitioner
    if (appointment.selected_practitioner?.id === ANY_PRACTITIONER_ID) return ANY_PRACTITIONER_ID;

    // Default emergency appointments to Any Practitioner
    if (this.emergencyAppointmentSelected) {
      return ANY_PRACTITIONER_ID;
    }
    let selected_practitioner = new BookableAppointmentPractitionerEntry();
    for (const practitioner of appointment.practitioners) {
      if (practitioner.is_preferred) {
        selected_practitioner = practitioner;
      } else {
        selected_practitioner = appointment.selected_practitioner ? appointment.selected_practitioner : appointment.practitioners[0];
      }
    }

    return selected_practitioner.id;
  }

  // eslint-disable-next-line complexity
  public get recallDate(): string | null {
    if (this._jwtService.isPatient()) {
      let recall_date;

      if (!this._patientService.patientInfo) return null;

      const { dentist_recall_date, hygienist_recall_date, isRoutineExamDue, isRoutineHygieneDue } = this._patientService.patientInfo;

      if (!this.selectedCategory) return null;

      const { dental_appointment, hygiene_appointment, supports_routine_appointments } = this.selectedCategory;
      if (supports_routine_appointments && dental_appointment && isRoutineExamDue) {
        recall_date = dentist_recall_date;
      }
      if (supports_routine_appointments && hygiene_appointment && isRoutineHygieneDue) {
        recall_date = hygienist_recall_date;
      }

      return recall_date ? dayjs(recall_date).format("YYYY-MM-DD") : null;
    }
    return null;
  }

  private _trackAppointmentTypeSelected() {
    if (!this.selectedAppointment) return;
    const { name, deposit, isNhs, price, fixed_price } = this.selectedAppointment;

    const book_together = (this.selectedAdditionalAppointments?.length ?? 0) > 0;
    this._appointmentAnalyticsService.trackTypeSelected(name, deposit || 0, isNhs, fixed_price || price, book_together);
    this._appointmentAnalyticsService.trackTypesSelected(this.selectedAppointment, this.selectedAdditionalAppointments);
  }

  private _getQueryParams(): Record<string, string> {
    this._selectedSiteId = this.selectedSite ? this.selectedSite.id : this.availabilityQueryParams?.site_id;
    const selected_practitioner_id = this._getSelectedPractitionerId(this.selectedAppointment);
    let queryParams: Record<string, string> = {};

    if (this.selectedAppointment) {
      queryParams = {
        appointment_type_id: this.selectedAppointment.appointment_type_id,
        site_id: this._selectedSiteId as string,
      };
      if (this.selectedAppointment.start_time) queryParams.start_time = this.selectedAppointment.start_time.format("YYYY-MM-DD");

      if (selected_practitioner_id) {
        queryParams.practitioner_id = selected_practitioner_id;
        const found_practitioner = this.selectedAppointment.practitioners.find((item) => item.id === selected_practitioner_id);
        if (found_practitioner) this.selectedAppointment.selected_practitioner = found_practitioner;
      }
    }

    if (this.selectedAdditionalAppointments && this.selectedAdditionalAppointments.length) {
      queryParams.additional_appointments = JSON.stringify(
        this.selectedAdditionalAppointments.map((item) => ({ id: item.appointment_type_id, practitioner_id: item.selected_practitioner.id }))
      );
    }

    return queryParams;
  }

  private _generateStartTime(startTime?: Dayjs): void {
    if (this.recallDate && !startTime) {
      const { max_days_ahead_bookings } = this._commonService.practice.appointment_settings;
      const is_recall_after_max_days = dayjs(this.recallDate).isAfter(dayjs().add(max_days_ahead_bookings, "days"));
      if (!is_recall_after_max_days) {
        // If the recall date is less than 30 days in the past, then set the start time to the date of the recall
        const number_of_days_before = dayjs(this.recallDate).subtract(30, "days").isBefore(dayjs()) || is_recall_after_max_days ? 0 : 30;
        if (this.selectedAppointment) this.selectedAppointment.start_time = dayjs(this.recallDate).subtract(number_of_days_before, "days");
      }
    }
  }

  public navigateToAvailability(startTime?: Dayjs, variant?: E_SlotVariantFeature | undefined) {
    this._generateStartTime(startTime);

    if (variant) this.slotVariant = variant;
    this._trackAppointmentTypeSelected();
    const queryParams = this._getQueryParams();
    this.availabilityQueryParams = queryParams;

    /*
      If the user is already on the availability page and they update any of the query params, then we want update the url without reloading the page
      and then manually trigger an availability search by emitting a new value for the onSelectedApptChanged subject
    */
    this.navigate("availability", queryParams, false);
    if (this._router.url.includes("/book/availability")) this.onSelectedApptChanged.next(this._selectedAppointment);
  }

  /*
    We use this function to get a new site appointment type for both a page refresh and when changing the site for the availability search refinement
    We use the site id along with the appointment type id to ensure that we get the correct list of sites and its corresponding site appointment type
  */
  public getNewSiteAppointmentType(site_id: string, appt: BookableAppointmentEntry, practitioner_id?: string): BookableAppointmentEntry {
    const sites = new Array<BookableAppointmentSiteEntry>();
    let appointmentObj = new BookableAppointmentEntry();

    for (const site of this.original_bookable_data) {
      for (const category of site.appointment_categories) {
        for (const appointment of category.site_appointment_types) {
          if (appointment.appointment_type_id === appt.appointment_type_id) {
            if (appointment.site_id === site_id) appointmentObj = this._createNewSiteAppointmentType(appointment);
            const new_site = new BookableAppointmentSiteEntry(site);
            sites.push(new_site);
          }
        }
      }
    }

    if (practitioner_id) {
      const found_practitioner = appointmentObj.practitioners.find((item) => item.id === practitioner_id);
      if (found_practitioner) appointmentObj.selected_practitioner = found_practitioner;
    }

    appointmentObj.sites = sites;
    return appointmentObj;
  }

  public get allSelectedAppointments(): Array<BookableAppointmentEntry> {
    return [this.selectedAppointment, ...(this.selectedAdditionalAppointments ?? [])].filter(isDefined);
  }

  public get selectedAppointment(): BookableAppointmentEntry | null {
    return this._selectedAppointment;
  }

  public set selectedAppointment(value: BookableAppointmentEntry | null) {
    this._selectedAppointment = value;
    this.onSelectedApptChanged.next(this._selectedAppointment);

    if (this._selectedAppointment) {
      this._cacheService.setSession(SELECTED_APPOINTMENT_KEY, JSON.stringify(this._selectedAppointment));
    } else {
      this._cacheService.deleteSession(SELECTED_APPOINTMENT_KEY);
    }
  }

  public get selectedAdditionalAppointments(): Array<BookableAppointmentEntry> | null {
    return this._selectedAdditionalAppointments;
  }

  public set selectedAdditionalAppointments(value: Array<BookableAppointmentEntry> | null) {
    this._selectedAdditionalAppointments = value;
    this.onSelectedAdditionalApptsChanged.next(this._selectedAdditionalAppointments);

    if (this._selectedAdditionalAppointments) {
      this._cacheService.setSession(SELECTED_ADDITIONAL_APPOINTMENTS_KEY, JSON.stringify(this._selectedAdditionalAppointments));
    } else {
      this._cacheService.deleteSession(SELECTED_ADDITIONAL_APPOINTMENTS_KEY);
    }
  }

  public get selectedSlot(): BookableAppointmentSlotEntry | null {
    return this._selectedSlot;
  }

  public set selectedSlot(value: BookableAppointmentSlotEntry | null) {
    // Set the initial status to SLOT_SELECTED to prevent other statuses affecting the next screen. For example, if SLOT_AVAILABLE was the
    // last action, then the next screen would show the availability error and prevent the patient from continuing the booking
    this._appointmentsService.onAppointmentStatusEvents.next({ action: E_AppointmentEventActions.SLOT_SELECTED });

    this._selectedSlot = value;
    this.onSelectedSlotChanged.next(this._selectedSlot);

    if (this._selectedSlot) {
      this._cacheService.setSession(SELECTED_SLOT_KEY, JSON.stringify(this._selectedSlot));
    } else {
      this._cacheService.deleteSession(SELECTED_SLOT_KEY);
    }
  }

  public get totalDeposit(): number {
    if (!this._selectedSiteId) this.restorePreviousSelections();

    const appointments = new Array<BookableAppointmentEntry>();
    if (this.selectedAppointment) appointments.push(this.selectedAppointment);
    if (this.selectedAdditionalAppointments) appointments.push(...this.selectedAdditionalAppointments);

    return appointments
      .map((item) => BookableAppointmentEntry.create(item))
      .filter((item) => !!item?.total_deposit)
      .reduce((acc, item) => {
        return acc + (item.total_deposit ?? 0);
      }, 0);
  }

  public get totalAmount(): number {
    const appointments = new Array<BookableAppointmentEntry>();
    if (this.selectedAppointment) appointments.push(this.selectedAppointment);
    if (this.selectedAdditionalAppointments) appointments.push(...this.selectedAdditionalAppointments);

    return appointments
      .map((item) => BookableAppointmentEntry.create(item))
      .filter((item) => !!item?.total_price)
      .reduce((acc, item) => {
        return acc + (item.total_price ?? 0);
      }, 0);
  }

  public get appointment_list_first(): boolean {
    return this._brandService.brand.appointment_list_first;
  }

  public get allSelectedAppointmentCategories(): Array<BookableAppointmentCategoryBase> {
    const appointmentTypeCategoryIds = new Array<string>();
    if (this.selectedAppointment) appointmentTypeCategoryIds.push(this.selectedAppointment.appointment_type_category_id);
    if (this.selectedAdditionalAppointments)
      appointmentTypeCategoryIds.push(...this.selectedAdditionalAppointments.map((item) => item?.appointment_type_category_id));

    const categories = new Array<BookableAppointmentCategoryBase>();

    for (const site of this.original_bookable_data) {
      for (const category of site.appointment_categories) {
        if (appointmentTypeCategoryIds.find((id) => id === category.id)) {
          //make sure we don't add a category twice
          if (!categories.find((c) => c.id === category.id)) categories.push(category);
        }
      }
    }

    return categories;
  }

  public set selectedCategory(value: BookableAppointmentCategoryEntry | null) {
    if (value) {
      this._appointmentAnalyticsService.trackCategorySelected(value.name, value.site_appointment_types);
    }
    this._selectedCategory = value;

    if (this._selectedCategory) {
      this._cacheService.setSession(SELECTED_CATEGORY_KEY, JSON.stringify(this._selectedCategory));
    } else {
      this._cacheService.deleteSession(SELECTED_CATEGORY_KEY);
    }
  }

  public get selectedCategory(): BookableAppointmentCategoryEntry | null {
    return this._selectedCategory;
  }

  public set selectedSite(value: BookableAppointmentSiteEntry | null) {
    this._selectedSite = value;
    this._cacheService.setSession(SELECTED_SITE, JSON.stringify(value));
  }

  public get selectedSite(): BookableAppointmentSiteEntry | null {
    return this._selectedSite;
  }

  public set bookableAppts(value: Array<BookableAppointmentSiteEntry> | Array<BookableAppointmentCategoryEntry>) {
    this._bookableAppts = value;
    this.onBookableApptsChanged.next(value);
  }

  public get bookableAppts(): Array<BookableAppointmentSiteEntry> | Array<BookableAppointmentCategoryEntry> {
    return this._bookableAppts;
  }

  public selectSite(site_id: string): void {
    const site = this.original_bookable_data.find((item) => item.id === site_id);
    this.selectedSite = new BookableAppointmentSiteEntry(site);
  }

  public restorePreviousSelections(): void {
    const selectedSite = this._cacheService.getSession(SELECTED_SITE);
    const selectedSiteId = this._cacheService.getSession(SELECTED_SITE_ID);
    const selectedCategory = this._cacheService.getSession(SELECTED_CATEGORY_KEY);
    const selectedAppointment = this._cacheService.getSession(SELECTED_APPOINTMENT_KEY);
    const selectedAdditionalAppointments = this._cacheService.getSession(SELECTED_ADDITIONAL_APPOINTMENTS_KEY);
    const selectedSlot = this._cacheService.getSession(SELECTED_SLOT_KEY);
    const availabilityQueryParams = this._cacheService.getSession(AVAILABILITY_QUERY_PARAMS_KEY);

    if (selectedSite) {
      this._selectedSite = new BookableAppointmentSiteEntry(JSON.parse(selectedSite));
    }

    if (selectedSiteId) {
      this._selectedSiteId = selectedSiteId;
    }

    if (selectedCategory) {
      this._selectedCategory = new BookableAppointmentCategoryEntry(JSON.parse(selectedCategory));
    }

    if (selectedAppointment) {
      this._selectedAppointment = new BookableAppointmentEntry(JSON.parse(selectedAppointment));
    }

    if (selectedAdditionalAppointments && selectedAdditionalAppointments !== "[]") {
      this._selectedAdditionalAppointments = JSON.parse(selectedAdditionalAppointments).map((item) => new BookableAppointmentEntry(item));
    }

    if (selectedSlot) {
      this._selectedSlot = new BookableAppointmentSlotEntry(JSON.parse(selectedSlot));
    }

    if (availabilityQueryParams) {
      this._availabilityQueryParams = JSON.parse(availabilityQueryParams);
    }
  }

  public clearPreviousSelections(): void {
    this._cacheService.deleteSession(SELECTED_SITE);
    this._cacheService.deleteSession(SELECTED_SITE_ID);
    this._cacheService.deleteSession(SELECTED_CATEGORY_KEY);
    this._cacheService.deleteSession(SELECTED_APPOINTMENT_KEY);
    this._cacheService.deleteSession(SELECTED_ADDITIONAL_APPOINTMENTS_KEY);
    this._cacheService.deleteSession(SELECTED_SLOT_KEY);
    this._cacheService.deleteSession(AVAILABILITY_QUERY_PARAMS_KEY);
    this._cacheService.deleteSession(HELD_APPOINTMENTS_KEY);
    this._appointmentsService.heldAppointments = [];
    this._selectedSite = null;
    this._selectedSiteIdStore = null;
    this._selectedCategory = null;
    this._selectedAppointment = null;
    this._selectedAdditionalAppointments = null;
    this._selectedSlot = null;
    this._availabilityQueryParams = null;
  }

  public async createFromQueryString(qs: Record<string, any>): Promise<void> {
    this.availabilityQueryParams = qs;
    await this.getBookableAppts();
  }

  public navigate(path: string, queryParams: Record<string, any> | null = null, skipLocationChange = true, params: NavigationExtras = {}) {
    const isPatient = this._jwtService.isPatient();
    const routePrefix = isPatient ? "my-dental/" : "";
    this._navigationService.navigate(`${routePrefix}book/${path}`, {
      skipLocationChange,
      queryParams,
      ...params,
    });
  }

  public getAppointmentCards(show_selected_practitioner = false): Array<AppointmentCardEntry> {
    const entries = new Array<AppointmentCardEntry>();
    for (const item of this.getSlotData(this.selectedSlot)) {
      const entry = AppointmentCardEntry.fromSelectedAppointmentSlot(item, show_selected_practitioner);

      const selected_appointments = new Array<BookableAppointmentEntry>();
      if (this.selectedAppointment) selected_appointments.push(this.selectedAppointment);
      if (this.selectedAdditionalAppointments) selected_appointments.push(...this.selectedAdditionalAppointments);
      entry.addPrices(selected_appointments);

      entries.push(entry);
    }

    return entries;
  }

  public selectPractitioner(site_appointment_type_id: string, practitioner_id: string): void {
    const appointment = this.allSelectedAppointments.find((appt) => appt?.id === site_appointment_type_id);

    if (!appointment) return;

    const found_practitioner = appointment.practitioners.find((item) => item.id === practitioner_id);
    if (found_practitioner) appointment.selected_practitioner = found_practitioner;
  }

  // eslint-disable-next-line complexity
  public getSlotData(slot: BookableAppointmentSlotEntry | null = this.selectedSlot): Array<I_SelectedAppointmentSlot> {
    if (slot) {
      const current_option = slot.options[slot.selected_option_index];

      const slots = new Array<I_SelectedAppointmentSlot>();
      for (const appointment of current_option.appointments) {
        const selected_appointment = this.allSelectedAppointments.find((appt) => appt?.id === appointment.site_appointment_type_id);

        if (!selected_appointment) continue;

        const practitioners = new Array<BookableAppointmentPractitionerEntry>();
        for (const option of slot.options) {
          for (const appt of option.appointments) {
            if (appt.site_appointment_type_id !== selected_appointment.id) continue;

            const found_practitioner = selected_appointment.practitioners.find((item) => item.id === appt.practitioner_id);
            // We check if the practitioner is already in the list as a practitioner could have multiple appointments at a particular time slot (eg Exam/Scale & Polish)
            if (found_practitioner && !practitioners.find((item) => item.id === found_practitioner.id)) practitioners.push(found_practitioner);
          }
        }
        SortPractitioners(practitioners);

        const selectedPractitionerId =
          (selected_appointment.selected_practitioner?.id === ANY_PRACTITIONER_ID ? null : selected_appointment.selected_practitioner?.id) ||
          appointment.practitioner_id;

        const found_practitioner = selected_appointment.practitioners.find((item) => item.id === selectedPractitionerId);

        const data = {
          name: selected_appointment.name,
          site_name: slot.site_name,
          start_time: dayjs(appointment.start_time).toDate(),
          duration: appointment.duration,
          practitioners,
          appointment_type_id: selected_appointment.appointment_type_id,
          appointment_type_reason: selected_appointment.appointment_type_reason,
          site_id: selected_appointment.site_id,
          site_appointment_type_id: appointment.site_appointment_type_id,
          site_address_line_1: selected_appointment.site_address_line_1,
          site_address_line_2: selected_appointment.site_address_line_2,
          site_address_line_3: selected_appointment.site_address_line_3,
          site_county: selected_appointment.site_county,
          site_postcode: selected_appointment.site_postcode,
          reason: selected_appointment.appointment_type_reason,
        } as I_SelectedAppointmentSlot;

        if (found_practitioner) data.practitioner = found_practitioner;

        slots.push(data);
      }

      return slots;
    }

    return [];
  }

  public async handleAppointmentBooking(
    paymentMethod?: string,
    redirectToSuccess = true
  ): Promise<{
    appointmentIds: Array<string>;
    bookedAppointmentCards: Array<AppointmentCardEntry>;
  } | void> {
    try {
      this.stripeCheckoutSessionStatus = E_StripeCheckoutSessionStatus.processing;

      if (this._jwtService.isPatientUnauthenticated()) this._commonService.getCommonData(true);

      const appointmentIds = (await this._appointmentsService.bookAppointments(this.isNewPatient)).map((id) => id.toString());

      this.isNewPatient = false;
      this.stripeCheckoutSessionStatus = E_StripeCheckoutSessionStatus.processed;

      this._appointmentsService.clearKeepAlive();
      this._appointmentAnalyticsService.trackBooked(paymentMethod);

      const completedActions = this._getCompletedActions();
      this._trackActionDoneMetrics(completedActions);
      if (completedActions.length) await this._shortCodesService.actionComplete();

      this._bookedAppointmentCards = this.getAppointmentCards(true);

      if (redirectToSuccess) {
        this._overlayService.close();

        await this._patientService.getPatient();

        let path = "/book/success";

        if (this._jwtService.isPatient()) {
          path = `/my-dental${path}`;
        }

        this._navigationService.navigate(path, {
          state: {
            appointmentIds,
            bookedAppointmentCards: this._bookedAppointmentCards,
          },
        });
      }

      return {
        appointmentIds,
        bookedAppointmentCards: this._bookedAppointmentCards,
      };
    } catch (error) {
      this.onAppointmentBookingError.next(true);
      console.error("error booking appointment", error);
      Bugsnag.notify(error);
    }
  }

  private _trackActionDoneMetrics(completedActions: Array<CompletedAction>) {
    try {
      for (const { site_id, type, referrer } of completedActions) {
        this._analyticsService.track(new METRIC.ActionDone(site_id, type, referrer));
      }
    } catch (e) {
      console.error("error tracking action done metrics", e);
      Bugsnag.notify(e);
    }
  }

  private _getCompletedActions(): Array<CompletedAction> {
    try {
      const completedActions = new Array<CompletedAction>();
      const { referrer } = this._jwtService.getJWT();
      for (const appointment of this.allSelectedAppointments) {
        const { appointment_type_category_id, site_id } = appointment;

        for (const type of [E_Patient_Actions_Type.ROUTINE_DENTAL_EXAM, E_Patient_Actions_Type.ROUTINE_HYGIENE_APPT]) {
          const actionData = this._patientActionsCacheService.patientActions.find((a) => a.type === type)?.data?.routine_appt_data;
          const actionIsForAppointmentTypeCategory = !!actionData?.find((a) => a.category_id === appointment_type_category_id);
          if (actionIsForAppointmentTypeCategory) {
            completedActions.push({ site_id, type, referrer });
          }
        }
      }

      return completedActions;
    } catch (e) {
      console.error("error getting completed actions", e);
      Bugsnag.notify(e);
      return [];
    }
  }

  public async handleSlotConfirmation(): Promise<{ depositAmount: number; totalAmount: number }> {
    for (const selected_appointment_slot of this.getSlotData()) {
      const bookable_appointment = this.findSiteAppointmentById(selected_appointment_slot.site_appointment_type_id);

      if (!bookable_appointment) {
        Bugsnag.notify(new Error("Bookable appointment not found"));

        continue;
      }

      if (selected_appointment_slot.site_appointment_type_id === this.selectedAppointment?.id) {
        this.selectedAppointment.selected_practitioner = selected_appointment_slot.practitioner;
      }
    }

    const { price: totalAmount, deposit: depositAmount } = await this._getAppointmentPrices();

    return { depositAmount, totalAmount };
  }

  private async _getAppointmentPrices(): Promise<{ price: number; deposit: number }> {
    return firstValueFrom(
      this._httpService
        .mutation(
          GRAPHQL_OPERATION_NAMES.GET_APPOINTMENT_PRICES,
          `{
      getAppointmentPrices(hostname: "${this._locationService.hostname}") {
        total {
          price
          deposit
        }
      }
    }`
        )
        .pipe(
          map((response) => {
            return response?.data?.getAppointmentPrices?.total || { price: 0, deposit: 0 };
          })
        )
    );
  }

  public trackSlotSelected(): void {
    try {
      this._appointmentAnalyticsService.trackSlotConfirmed(this.getSlotData());
    } catch {
      // We don't want to block the booking if the tracking fails
    }
  }

  private _bookableApptDataByApptType(data: Array<BookableAppointmentSiteBase>): Array<BookableAppointmentCategoryEntry> {
    const bookable_appts_by_appt_type = Array<BookableAppointmentCategoryEntry>();

    for (const site of data) {
      const new_site = cloneDeep(site);
      const site_entry = new BookableAppointmentSiteEntry(new_site);

      for (const category of site.appointment_categories) {
        let found_appt_catgory = bookable_appts_by_appt_type.find((item) => item.id === category.id);
        if (!found_appt_catgory) {
          const new_appt_category = cloneDeep(category);
          delete new_appt_category.site_appointment_types;
          found_appt_catgory = new BookableAppointmentCategoryEntry(new_appt_category);
          bookable_appts_by_appt_type.push(found_appt_catgory);
        }

        for (const appointment_type of category.site_appointment_types) {
          let found_appointment_type = found_appt_catgory.site_appointment_types.find(
            (item) => item.appointment_type_id === appointment_type.appointment_type_id
          );
          if (!found_appointment_type) {
            found_appointment_type = new BookableAppointmentEntry(appointment_type);
            found_appt_catgory.site_appointment_types.push(found_appointment_type);
          } else {
            for (const practitioner of appointment_type.practitioners) {
              const found_practitioner = found_appointment_type.practitioners.find((item) => item.id === practitioner.id);
              if (!found_practitioner) {
                const new_practitioner = new BookableAppointmentPractitionerEntry(practitioner);
                found_appointment_type.practitioners.push(new_practitioner);
              }
            }
          }
          found_appointment_type.sites.push(site_entry);
        }
      }
    }

    return bookable_appts_by_appt_type;
  }

  //#region Patient bookable status
  public get patientBookableStatus(): PatientStatus {
    const status = new PatientStatus(this._patientService.patientInfo, this._commonService.practice.appointment_settings);
    return status;
  }

  public get isPatientDentalLapsed(): boolean {
    return this.patientBookableStatus.dental === E_PatientStatus.lapsed;
  }

  public get isPatientDentalNew(): boolean {
    return this.patientBookableStatus.dental === E_PatientStatus.new;
  }

  public get isPatientDentalExisting(): boolean {
    return this.patientBookableStatus.dental === E_PatientStatus.existing;
  }

  public get isPatientHygieneLapsed(): boolean {
    return this.patientBookableStatus.hygiene === E_PatientStatus.lapsed;
  }

  public get isPatientHygieneNew(): boolean {
    return this.patientBookableStatus.hygiene === E_PatientStatus.new;
  }

  public get isPatientHygieneExisting(): boolean {
    return this.patientBookableStatus.hygiene === E_PatientStatus.existing;
  }
  //#endregion

  public get hasMultiplePractitioners(): boolean | null {
    return this.getHasMultiplePractitioners(true);
  }

  public getHasMultiplePractitioners(firstApptReqd: boolean): boolean | null {
    // Filter out "Any" practitioner
    const appointment = firstApptReqd ? this.selectedAppointment : this.selectedAdditionalAppointments?.[0];
    if (appointment) return appointment.practitioners.filter((item) => item.id !== ANY_PRACTITIONER_ID).length > 1;
    return null;
  }

  public get allFirstAppointmentPractitionersSearched(): boolean {
    const firstAppt = this.selectedAppointment;
    if (!firstAppt) return false;

    return (
      firstAppt.practitioners_locked ||
      !firstAppt.selected_practitioner ||
      firstAppt.selected_practitioner.id === ANY_PRACTITIONER_ID ||
      !this.getHasMultiplePractitioners(true)
    );
  }

  public get isBookTogether(): boolean {
    return (this.selectedAdditionalAppointments ?? []).length > 0;
  }

  public get allSecondAppointmentPractitionersSearched(): boolean {
    const secondAppt = this.selectedAdditionalAppointments?.[0];
    return (
      secondAppt?.practitioners_locked ||
      !secondAppt?.selected_practitioner ||
      secondAppt?.selected_practitioner.id === ANY_PRACTITIONER_ID ||
      !this.getHasMultiplePractitioners(false)
    );
  }

  public get allPractitionersSearched(): boolean {
    const allFirstSearched = this.allFirstAppointmentPractitionersSearched;

    if (!this.isBookTogether) return allFirstSearched;
    return allFirstSearched && this.allSecondAppointmentPractitionersSearched;
  }

  public onSearchAnyPractitioner(): void {
    if (!this.allFirstAppointmentPractitionersSearched) {
      const firstAppt = this.selectedAppointment;
      const found_practitioner =
        firstAppt?.practitioners.length === 1 ? firstAppt.practitioners[0] : firstAppt?.practitioners.find((p) => p.id === ANY_PRACTITIONER_ID);
      if (found_practitioner && firstAppt) firstAppt.selected_practitioner = found_practitioner;
    }

    if (this.isBookTogether && !this.allSecondAppointmentPractitionersSearched && this.selectedAdditionalAppointments?.[0]) {
      const secondAppt = this.selectedAdditionalAppointments[0];
      const found_practitioner =
        secondAppt.practitioners.length === 1 ? secondAppt.practitioners[0] : secondAppt.practitioners.find((p) => p.id === ANY_PRACTITIONER_ID);
      if (found_practitioner) secondAppt.selected_practitioner = found_practitioner;
    }
    this.navigateToAvailability();
  }

  private _bookableApptDataBySite(data: Array<BookableAppointmentSiteBase>): Array<BookableAppointmentSiteEntry> {
    const bookable_appts_by_site = new Array<BookableAppointmentSiteEntry>();
    for (const site of data) {
      bookable_appts_by_site.push(new BookableAppointmentSiteEntry(site));
    }

    return bookable_appts_by_site;
  }

  private _getQuery(): string {
    let siteId = "";

    if (!this._jwtService.isPatient()) {
      siteId = this._brandService.restrictedSiteId;
    } else if (this._brandService.brand?.site_locking_enabled) {
      siteId = this._patientsService?.patientInfo?.site_id ?? "";
    }

    return `{
      practice {
        nhs_payment_plan_id,

      },
      bookable_appointments(hostname:"${this._locationService.hostname}"${siteId ? `, site_id:"${siteId}"` : ""}) {
        items {
          address_line_1
          address_line_2
          address_line_3
          town
          county
          postcode

          id
          name
          phone_number
          appointment_categories {
            dental_appointment
            description
            for_new_patients
            hygiene_appointment
            id
            supports_routine_appointments
            name
            supports_nhs
            site_appointment_types {
              appointment_type_category_id
              appointment_type_category_name
              appointment_type_id
              appointment_type_reason
              default_duration
              deposit
              description
              fixed_price
              id
              name
              payment_provider_id
              payment_provider_name
              payment_provider_logo_url
              plan_allowance_amount
              plan_allowance_interval
              plan_discount
              plan_id
              plan_name
              price
              practitioners {
                active
                biography
                colour
                duration
                first_name
                id
                image_url
                is_preferred
                last_name
                role
                title
              }
              practitioners_locked
              site_id
              site_name

              site_address_line_1
              site_address_line_2
              site_address_line_3
              site_town
              site_county
              site_postcode
            }
          }
        }
      }
    }
    `;
  }

  public findSiteAppointmentById(id: string): BookableAppointmentBase | null {
    for (const appointment of this.original_bookable_data ?? []) {
      for (const appointmentCategory of appointment.appointment_categories ?? []) {
        for (const siteAppointment of appointmentCategory.site_appointment_types ?? []) {
          if (siteAppointment.id === id) {
            return siteAppointment;
          }
        }
      }
    }
    return null;
  }

  public async getBookableAppts(): Promise<void> {
    // Initialise these to something safe since we might get multiple calls and need to ensure things are reset correctly
    this._graphError = null;
    this.bookableAppts = [];

    try {
      // Convert the observable to a promise and wait for it to resolve
      const response = await firstValueFrom(
        this._cachedHttpService.query<BookableAppointmentSiteBase>(GRAPHQL_OPERATION_NAMES.GET_BOOKABLE_APPOINTMENTS, this._getQuery(), { ttl: 3600 })
      );

      if (response.errors || !response.data.bookable_appointments?.items.length) return;

      let bookable_appts;

      this.original_bookable_data = response.data.bookable_appointments.items;

      this.original_bookable_data.forEach((item) => {
        item.appointment_categories.forEach((apptCat) => {
          apptCat.site_appointment_types.forEach((apptType) => {
            apptType.practitioners.forEach((practitioner) => {
              this._practitionersService.addPractitionerColorClasses(practitioner as BookableAppointmentPractitionerEntry);
            });
          });
        });
      });

      if (!this.appointment_list_first) {
        bookable_appts = this._bookableApptDataBySite(response.data.bookable_appointments.items);
      } else {
        bookable_appts = this._bookableApptDataByApptType(response.data.bookable_appointments.items);
      }

      this.bookableAppts = bookable_appts;
    } catch (err) {
      this._graphError = err;
    }
  }

  // eslint-disable-next-line complexity
  public get shouldOfferBookTogether(): boolean {
    try {
      if (
        !this._patientsService.patientInfo ||
        !this.selectedCategory?.supports_routine_appointments ||
        !this._commonService.practice.appointment_settings.can_book_together
      )
        return false;

      // Must have no exam and no hygiene appointment already booked
      if (!this._patientsService.patientInfo.can_book_together_hygiene) return false;
      if (!this._patientsService.patientInfo.can_book_together_dental) return false;

      // Must be a routine exam we're booking
      if (!this.selectedCategory.supports_routine_appointments) return false;

      // If we're booking a dental exam, must be a routine hygiene exam we look for next
      if (this.selectedCategory.dental_appointment) {
        const hygieneCat = this.appointmentCategories.find((cat) => cat.hygiene_appointment && cat.supports_routine_appointments);
        if (hygieneCat) return true;
      }

      // If we're booking a hygiene exam, must be a routine dental exam we look for next
      if (this.selectedCategory.hygiene_appointment) {
        const dentalCat = this.appointmentCategories.find((cat) => cat.dental_appointment && cat.supports_routine_appointments);
        if (dentalCat) return true;
      }
    } catch (error) {
      Bugsnag.notify(new Error(`Failed to determine if book together should be offered: ${error}`));
    }

    return false;
  }

  public get appointmentCategories(): Array<BookableAppointmentCategoryEntry> {
    if (this.appointment_list_first) return this.bookableAppts as Array<BookableAppointmentCategoryEntry>;

    if (!this.selectedSite) return [];

    return this.selectedSite.appointment_categories;
  }

  public updateSelectedAdditionalAppointmentsPractitioner(practitioner: BookableAppointmentPractitionerEntry) {
    if (!this.selectedAdditionalAppointments) return;

    if (this.selectedAdditionalAppointments.length) {
      this.selectedAdditionalAppointments.forEach((appt) => {
        appt.selected_practitioner = practitioner;
      });
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public get slotVariant(): E_SlotVariantFeature {
    return this._slotVariant;
  }
  public set slotVariant(value: E_SlotVariantFeature) {
    this._slotVariant = value;
  }

  public handleRoutineAppointmentAction(category: BookableAppointmentCategoryEntry): void {
    this.selectedCategory = category;

    let matchingAppts = category.site_appointment_types;

    // Filter the list of appointments to the patient's payment plan if the appointment is a routine dental checkup
    // If the patient is NHS and there are no NHS appointments, they'll be offered any available private appointments
    // If there are no matching appointments and the patient is not NHS, offer them any private appointment
    if (category.dental_appointment && this._patientsService.patientInfo) {
      const { payment_plan_id } = this._patientsService.patientInfo;

      matchingAppts = matchingAppts.filter((appt) => appt.plan_id === payment_plan_id);

      const { nhs_payment_plan_id } = this._commonService.practice;

      if (!matchingAppts.length && payment_plan_id !== nhs_payment_plan_id) {
        matchingAppts = category.site_appointment_types.filter((appt) => appt.plan_id !== nhs_payment_plan_id);
      }
    }

    // If there is only one appointment type, select it and redirect to the next step (practitioner, book together or availability)
    if (matchingAppts.length === 1) {
      this.selectedAppointment = matchingAppts[0];

      if (this.shouldOfferBookTogether) {
        this.navigate("additional-appointments");
        return;
      }

      this.afterSelectAppointment();
      return;
    }

    this.navigate("appointment");
  }

  public afterSelectAppointment(): void {
    if (!this.selectedAppointment) {
      Bugsnag.notify(new Error("No selected appointment in afterSelectAppointment()"));
      return;
    }

    // If the practice is single site, has site locking enabled, is using the location first flow
    if (
      !this._commonService.practice.is_multisite ||
      (this._brandService.brand.site_locking_enabled && this._jwtService.isPatient()) ||
      !this.appointment_list_first
    ) {
      if (this.showPractitionerSelectionScreen) {
        this.navigate("practitioner");
      } else {
        this.navigateToAvailability();
      }
    } else {
      const numberOfSites = this.selectedAppointment?.sites.length;

      if (numberOfSites === 1) {
        this.selectedSite = this.selectedAppointment.sites[0];
        this.navigateToAvailability();
      } else {
        this.navigate("location");
      }
    }
  }

  public get selectedOrRestrictedSite(): BrandInfoSiteBase | null {
    if (this._brandService.restrictedSite) {
      const found_site = this._brandService.brand.sites.find((s) => s.site_id === this._brandService.restrictedSite?.site_id);
      if (found_site) return found_site;
    }

    if (this.selectedSite) {
      const found_site = this._brandService.brand.sites.find((s) => s.site_id === this.selectedSite?.id);
      if (found_site) return found_site;
    }

    return null;
  }

  public getSiteToContact(): BrandInfoSiteBase | null {
    const { selectedOrRestrictedSite } = this;

    if (selectedOrRestrictedSite?.site_phone_number) return selectedOrRestrictedSite;

    const { sites } = this._brandService.brand;
    if (!sites.length) return null;
    if (!this.selectedSite) return sites[0] || null;

    const site = sites.find((s) => s.site_id === this.selectedSite?.id);
    if (!site?.site_phone_number) return null;
    return site;
  }

  public createStripeCheckoutSession(isNewPatient: boolean): void {
    const appointments = new Array<BookableAppointmentEntry>();
    if (this.selectedAppointment) appointments.push(this.selectedAppointment);
    if (this.selectedAdditionalAppointments) appointments.push(...this.selectedAdditionalAppointments);

    const siteAppointmentTypes = appointments.map((appt) => {
      if (!appt) return;
      return {
        id: appt.id,
        practitioner_id: appt.selected_practitioner.id,
        site_id: appt.site_id,
      };
    }) as Array<{ id: string; practitioner_id: string; site_id: string }>;

    this._paymentsService
      .createStripeCheckoutSession(
        siteAppointmentTypes,
        this._appointmentsService.heldAppointments.map((appt) => appt.id.toString()),
        isNewPatient
      )
      .subscribe(() => {
        this.stripeCheckoutSessionStatus = E_StripeCheckoutSessionStatus.created;
      });
  }

  public async bookNewPatientAppointment() {
    const { depositAmount } = await this.handleSlotConfirmation();

    this.isNewPatient = true;
    if (depositAmount > 0) {
      this.createStripeCheckoutSession(true);
    } else {
      await this.handleAppointmentBooking();
    }
  }
}
