import { Injectable } from "@angular/core";
import { CacheService } from "./cache.service";
import { environment } from "src/environments/environment";
import { isRootDomain } from "src/app/utils";

const HOSTNAME_STORAGE_KEY = "hostname";
const ACTUAL_HOSTNAME_STORAGE_KEY = "actual-hostname";
const { HOST_ZONE, STAGE } = environment;

@Injectable({
  providedIn: "root",
})
export class LocationService {
  private _hostname;
  private _actualHostname;

  constructor(private _cacheService: CacheService) {
    try {
      this._hostname = this._cacheService.getSession(HOSTNAME_STORAGE_KEY);
      this._actualHostname = this._cacheService.getSession(ACTUAL_HOSTNAME_STORAGE_KEY);
    } catch {}
  }

  public get hostname(): string {
    return this._hostname || window.location.hostname;
  }

  public set hostname(hostname: string) {
    this._hostname = hostname;

    this._cacheService.setSession(HOSTNAME_STORAGE_KEY, hostname);
  }

  public set actualHostname(hostname: string) {
    this._actualHostname = hostname;

    this._cacheService.setSession(ACTUAL_HOSTNAME_STORAGE_KEY, hostname);
  }

  /**
   * Gets the actual hostname of the current window. Use hostname to get the relative hostname (used to make global domains work)
   */
  public get actualHostname(): string {
    return this._actualHostname || window.location.hostname;
  }

  public get pathname(): string {
    return window.location.pathname;
  }

  public set href(href: string) {
    window.location.href = href;
  }

  public get isPairDomain(): boolean {
    const domains = [`beta.${this._stage}.${HOST_ZONE}`, `beta.${HOST_ZONE}`, `pair.${this._stage}.${HOST_ZONE}`, `pair.${HOST_ZONE}`];

    return domains.includes(window.location.hostname);
  }

  private get _stage(): string {
    return STAGE;
  }

  public get isPreferencesDomain(): boolean {
    const regex = new RegExp(`^preferences\.(${this._stage}\.)?${HOST_ZONE}$`);

    return regex.test(this.actualHostname);
  }

  public get isRootDomain(): boolean {
    return isRootDomain(this._stage);
  }

  public get search(): Record<string, string> {
    const params = new URLSearchParams(window.location.search) as any;

    return Object.fromEntries([...params]) as Record<string, string>;
  }
}
