import { environment } from "src/environments/environment";

export function hideAppLoadingSpinner(): void {
  const appLoaderSpinner = document.getElementById("app_loader_spinner");
  if (appLoaderSpinner) appLoaderSpinner.outerHTML = "";
}

export function isRootDomain(stage?: string, hostname = window.location.hostname): boolean {
  const rootDomains: Array<string> = [environment.HOST_ZONE];

  if (stage) {
    rootDomains.push(`${stage}.${environment.HOST_ZONE}`);
  }

  return rootDomains.includes(hostname);
}
