import { Injectable } from "@angular/core";
import { filter, map, Observable, BehaviorSubject } from "rxjs";
import { filterDefined } from "../utils/rxjs";

type T_EventNames = "bugsnag:ready";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  private _subjects = new BehaviorSubject<{
    name: T_EventNames;
    data: any;
  } | null>(null);

  public listen(eventName: "bugsnag:ready"): Observable<void>;
  public listen(eventName: T_EventNames): Observable<any> {
    return this._subjects.pipe(
      filterDefined(),
      filter((event) => event.name === eventName),
      map((event) => event.data)
    );
  }

  public emit(eventName: "bugsnag:ready"): void;
  public emit(eventName: T_EventNames, data?: any): void {
    this._subjects.next({ name: eventName, data });
  }
}
