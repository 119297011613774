import { DOCUMENT } from "@angular/common";
import { Component, HostListener, OnDestroy, ChangeDetectorRef, Renderer2, Inject, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import dayjs from "dayjs";
import { timer } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SubSink } from "subsink";
import { ChatComponent } from "./chat/chat.component";
import { MainComponent } from "./main/main.component";
import { NotificationComponent } from "./shared/components/notification/notification.component";
import { FeatureFlagsService } from "./shared/services/feature-flags.service";
import { JWTService } from "./shared/services/jwt.service";
import { NotificationInstance, NotificationService, NotificationTypes } from "./shared/services/notification.service";
import { OverlayService } from "./shared/services/overlay.service";
import { PushUpdatesService } from "./shared/services/push-updates.service";
import { FADE_IN_ANIMATION, FAST_ANIMATION_DURATION } from "./shared/utils/animations";
import { SHARED } from "./shared/shared";
import { NavigationService } from "./shared/services/navigation.service";
import { CacheService } from "./shared/services/cache.service";
import { Constants } from "src/constants";
import { PwaService } from "./shared/services/pwa.service";
import { SessionService } from "./shared/services/session.service";

const { HOST_ZONE } = environment;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [NotificationComponent, SHARED, MainComponent, ChatComponent],
  animations: [FADE_IN_ANIMATION],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = "app";
  private _subs = new SubSink();
  public loading = true;
  public appView = false;
  public hideTopNav = false;
  public isOverlayOpen: boolean;
  public isOverlayCloseable = true;
  public installPrompt: any;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler() {
    this.closeOverlay();
  }
  @HostListener("window:beforeunload", ["$event"]) beforeUnloadHandler() {
    this._sessionService.onPageUnload();
  }
  @HostListener("window:unload", ["$event"]) unloadHandler() {}
  @HostListener("window:message", ["$event"]) async onMessage(event) {
    // Used when the patient app is being shown in an iframe so we can logout before changing to a different portal/user
    // Sandbox and production use dentally.com domains (e.g. portal.dentally.com) and other stages use stage-manage.sandbox.portal.dental
    if ((event.origin.endsWith(`.${HOST_ZONE}`) || event.origin.endsWith(".dentally.com")) && event.data === "signout") {
      await this._sessionService.logout();
    }
  }

  constructor(
    private _router: Router,
    private _jwtService: JWTService,
    private _activatedRoute: ActivatedRoute,
    private _overlayService: OverlayService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _renderer: Renderer2,
    private _featureFlagsService: FeatureFlagsService,
    private _pushUpdatesService: PushUpdatesService,
    private _notificationService: NotificationService,
    private _navigationService: NavigationService,
    private _cacheService: CacheService,
    private _pwaService: PwaService,
    private _sessionService: SessionService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._subs.sink = this._overlayService.onOverlayVisibilityChange.subscribe((is_open) => {
      this.isOverlayOpen = is_open;
      this._changeDetectorRef.detectChanges();
      const method = is_open ? "addClass" : "removeClass";
      this._renderer[method](this._document.body, "overflow-hidden");
    });
    this._subs.sink = this._overlayService.onOverlayCloseableChange.subscribe((isOverlayCloseable) => {
      this.isOverlayCloseable = isOverlayCloseable;
    });

    if (this._jwtService.isLoggedIn() && this._sessionService.isPasswordRequired) {
      // Show the page to prevent lack of route change from showing a blank page when the patient is expected to re-enter their password
      this.loading = false;
    }

    this._subs.sink = this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          const isMyDental = event.url.includes("/my-dental");
          const isPublic = this._jwtService.isPUBLIC();
          if (isMyDental && isPublic) {
            this._navigationService.navigate("/");
            return;
          }

          const has_shortcut = this._activatedRoute.snapshot.queryParamMap.get("shortcut");
          if (has_shortcut) {
            this._cacheService.setSession(Constants.PWA_SHORTCUT_STORAGE_KEY, has_shortcut);
          }

          this.loading = true;
          // Manual url tracking for HotJar
          if (window.hj) window.hj("stateChange", event.url);
          if (window.ga) {
            (<any>window).ga("set", "page", event.urlAfterRedirects);
            (<any>window).ga("send", "pageview");
            if (window.clientTracker) {
              (<any>window).ga("clientTracker.set", "page", event.urlAfterRedirects);
              (<any>window).ga("clientTracker.send", "pageview");
            }
          }

          try {
            setTimeout(() => {
              document.body.scrollTop = 0;
              window.scrollTo(0, 0);
            }, FAST_ANIMATION_DURATION); // Delay to match the animation duration of the router outlet
          } catch (err) {
            return err;
          }
        }),
        map(() => this._activatedRoute.snapshot)
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this._handleRouteData(route);
        return route;
      });

    this._handleRouteData(this._activatedRoute.snapshot);
  }

  private _handleRouteData(route: ActivatedRouteSnapshot) {
    if (!route) return;

    while (route.firstChild) {
      route = route.firstChild;
      this.appView = !!route.data.appView;
      this.hideTopNav = !!route.data.hideTopNav;
    }
    this.loading = false;
  }

  async ngOnInit() {
    await this._pwaService.unregisterServiceWorker();
    await this._pwaService.checkUpdates();

    if (this._featureFlagsService.recaptcha) this._addGoogleRecaptchaScript();

    const isPatient = this._jwtService.isPatient();
    const pwaStorageShortcut = this._cacheService.getSession(Constants.PWA_SHORTCUT_STORAGE_KEY);

    // If we have a saved shortcut in session storage from clicking a PWA shortcut, navigate to that page
    if (pwaStorageShortcut && isPatient) {
      this._navigationService.navigate(`/my-dental/${pwaStorageShortcut}`);
      this._cacheService.deleteSession(Constants.PWA_SHORTCUT_STORAGE_KEY);
    }

    this._pushUpdatesService.connect(this._jwtService.getJWTString());
    this._bindEvents();
  }

  private _bindEvents() {
    this._subs.sink = this._pushUpdatesService.downForMaintenanceSubject.pipe(filter((data) => data.status === "down")).subscribe((data) => {
      this._notificationService.open(
        new NotificationInstance({
          title: "Maintenance advance notice",
          body: `Portal will go down for maintenance at ${dayjs()
            .add(data.delay / 1000, "seconds")
            .format("h:mm a")}`,
          type: NotificationTypes.INFO,
          timeout: data.delay,
        })
      );

      this._subs.sink = timer(data.delay).subscribe(() => {
        window.location.reload();
      });
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public closeOverlay(): void {
    if (!this.isOverlayCloseable || !this.isOverlayOpen) return;

    this._overlayService.close();
  }

  private _addGoogleRecaptchaScript() {
    const script = this._document.createElement("script");
    const head = this._document.getElementsByTagName("head")[0];

    script.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${environment.GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;

    head.appendChild(script);
  }
}
