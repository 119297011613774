import { ILocalisationConfig } from "./localisation.interface";
import defaults from "./localisation-defaults";
import au from "./localisation-au";
import nz from "./localisation-nz";

export const Localisation: Record<string, ILocalisationConfig> = {
  defaults,
  au,
  nz,
};
